/* eslint-disable react/prop-types */
import React from 'react';

const MobileBlockList = ({ blocks }) => (
    <div className="">
        {blocks.map((block, index) => (
            <div key={index} className="bg-grey-white p-6 rounded-md mb-4">
                <h3 className="font-bold text-lg leading-normal mb-2">{block.title}</h3>
                <p className="text-base leading-normal">{block.description}</p>
            </div>
        ))}
    </div>
);

export default MobileBlockList;
