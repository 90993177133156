/* eslint-disable react/prop-types */
import React from 'react';

const BlockList = ({ array, second }) => (
    <div className={`${second ? 'embla__container__second' : 'embla__container '} gap-2`}>
        {array &&
            array.map((block, index) => (
                <div
                    key={index}
                    className={`${
                        second ? 'embla__slide__second' : 'embla__slide'
                    } bg-grey-white lg:bg-grey-ghostWhite p-6 rounded-md flex flex-col justify-center`}
                >
                    <h3 className="font-bold text-lg leading-normal mb-2">{block.title}</h3>
                    <p className="text-sm leading-normal">{block.description}</p>
                </div>
            ))}
    </div>
);

export default BlockList;
