import styled from 'styled-components';
import { variables } from '@/assets/styled';

export const StyledAboutYouCard = styled.div`
    transition: box-shadow ease 200ms;
    :hover {
        box-shadow: 0px 10px 30px 0px rgba(0, 147, 255, 0.5);
        .title {
            color: ${variables.colors.blue.default} !important;
        }
        .icon {
            color: ${variables.colors.blue.default} !important;
        }
    }
`;
