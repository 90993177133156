import styled from 'styled-components';

export const StyledSliderDiv = styled.div`
    height: 150px;
    min-height: 150px;
    cursor: grab;

    &:active {
        cursor: grabbing;
    }

    .embla__container {
        display: flex;
        user-select: none;
        -webkit-touch-callout: none;
        -khtml-user-select: none;
        -webkit-tap-highlight-color: transparent;
        height: 150px;
        min-height: 150px;
        max-width: 500px;

        &__second {
            display: flex;
            user-select: none;
            height: 150px;
            min-height: 150px;
            max-width: 1000px;
        }
    }
    .embla__slide {
        position: relative;
        min-width: 100%;
        opacity: 0.6;
        height: 150px;
        min-height: 150px;

        &__second {
            position: relative;
            min-width: 50%;
            opacity: 0.6;
            height: 150px;
            min-height: 150px;
        }
    }

    .in-focus {
        opacity: 1;
    }

    @media screen and (max-width: 1140px) {
        .embla__second {
            width: 750px;
        }
        .embla__container__second {
            max-width: 750px;
        }
    }
`;
