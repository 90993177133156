import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Transition } from '@headlessui/react';
import CallToActionBlock from '@/components/CallToActionBlock';
import { StyledCloseMagnetContainer } from '@/components/MagnetBlock/styles';
import Icon from '@/components/Icon';
import { useGlobalMagnetBlock } from '../../context/MagnetBlockContext';

const MagnetBlock = ({ configuration }) => {
    const { display_type, display_after_seconds, display_after_scroll } = configuration;
    configuration.type = configuration.magnet_type;

    const [show, setShow] = React.useState(display_type === 'always');
    const [hasBeenShown, setHasBeenShown] = useGlobalMagnetBlock();

    const showMagnet = () => {
        if (hasBeenShown) {
            return;
        }
        setHasBeenShown(true);
        setShow(true);
    };

    const hideMagnet = () => {
        setShow(false);
    };

    useEffect(() => {
        let timer;
        if (display_type === 'seconds' || display_type === 'seconds_and_scroll') {
            timer = setTimeout(showMagnet, display_after_seconds * 1000);
        }
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [display_type, display_after_seconds, showMagnet]);

    useEffect(() => {
        const handleScroll = () => {
            if (Math.abs(window.scrollY) > window.innerHeight * (display_after_scroll / 100)) {
                showMagnet();
            }
        };

        if (display_type === 'scroll' || display_type === 'seconds_and_scroll') {
            window.addEventListener('scroll', handleScroll);
        }

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [display_type, display_after_scroll, showMagnet]);

    return (
        <>
            <div className="fixed right-0 mb-4 bottom-0 px-4 lg:px-0 lg:m-6 w-full lg:w-full lg:max-w-md z-20">
                <Transition
                    show={show}
                    enter="transform transition ease-in-out duration-500"
                    enterFrom="translate-y-full"
                    enterTo="translate-y-0"
                    leave="transform transition ease-in-out duration-500"
                    leaveFrom="translate-y-0"
                    leaveTo="translate-y-full"
                >
                    <StyledCloseMagnetContainer
                        className="absolute right-0 top-0 mt-4 md:-mt-6 lg:mt-2 -ml-10 mr-2 lg:mr-0 bg-grey-white rounded-full shadow-sm w-8 h-8 z-30 flex items-center justify-center border border-grey-light cursor-pointer"
                        onClick={hideMagnet}
                    >
                        <Icon name="close" size={12} />
                    </StyledCloseMagnetContainer>
                    <CallToActionBlock
                        configuration={configuration}
                        className="shadow-magnet rounded-md max-h-[calc(100vh_-_112px_-_1rem)] lg:max-h-[calc(100vh_-_136px_-_1.5rem)] overflow-y-auto"
                    />
                </Transition>
            </div>
        </>
    );
};

MagnetBlock.propTypes = {
    configuration: PropTypes.shape({
        id: PropTypes.number,
        display_type: PropTypes.string,
        display_after_seconds: PropTypes.number,
        display_after_scroll: PropTypes.number,
        title: PropTypes.string,
        subtitle: PropTypes.string,
        button_text: PropTypes.string,
        type: PropTypes.string,
        magnet_type: PropTypes.string,
        url: PropTypes.string,
        input_fields: PropTypes.arrayOf(
            PropTypes.shape({
                type: PropTypes.string,
                width: PropTypes.string,
                placeholder: PropTypes.string,
            })
        ),
        image: PropTypes.shape({
            url: PropTypes.string,
            width: PropTypes.number,
            height: PropTypes.number,
            alternativeText: PropTypes.string,
        }),
        image_aside: PropTypes.shape({
            url: PropTypes.string,
            alternativeText: PropTypes.string,
        }),
        file: PropTypes.shape({
            id: PropTypes.number,
        }),
    }),
};

export default MagnetBlock;
