import PropTypes from 'prop-types';
import React from 'react';
import Number from './components/Number';

const BusinessFigures = ({ digits }) => (
    <div className="flex justify-center w-full container relative">
        <div className="py-6 app-m-w flex flex-wrap">
            {digits.map((value, index) => (
                <Number key={index} digit={value.number} text={value.description} />
            ))}
        </div>
    </div>
);

BusinessFigures.propTypes = {
    digits: PropTypes.array,
};

export default BusinessFigures;
