import styled from 'styled-components';
import { variables } from '@/assets/styled';

export const StyledHeroWrapper = styled.div`
    position: relative;
    overflow: hidden;
    div {
        z-index: -1;
    }
    section {
        * {
            z-index: 10;
        }
    }

    .hero-image {
        object-position: right !important;
    }

    .hero-title {
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        hyphens: manual;
    }

    @media (min-width: ${variables.screens.xs}) {
        .hero-wrapper {
            padding-bottom: 6rem;
        }
    }

    @media (min-width: ${variables.screens.sm}) {
        .hero-wrapper {
            padding-bottom: 6rem;
        }
    }

    @media (min-width: ${variables.screens.md}) {
        .hero-wrapper {
            padding-bottom: 8rem;
        }
    }

    @media (min-width: ${variables.screens.lg}) {
        .hero-wrapper {
            padding-bottom: 10rem;
        }
    }

    @media (min-width: ${variables.screens.xl}) {
        .hero-wrapper {
            padding-bottom: 11rem;
        }
    }
`;
