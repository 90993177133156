import PropTypes from 'prop-types';
import useEmblaCarousel from 'embla-carousel-react';
import { useEffect, useState } from 'react';
import ClassNames from 'embla-carousel-class-names';
import BlockList from './BlockList';
import MobileBlockList from './MobileBlockList';
import { StyledSliderDiv } from './styles';

const options = { selected: 'in-focus' };

const USPBlock = ({ title, blocks }) => {
    const [emblaRef] = useEmblaCarousel({ loop: false, align: 'start', startIndex: 1 }, [ClassNames(options)]);
    const [emblaRef2] = useEmblaCarousel({ loop: false, align: 'start', startIndex: 0 }, [ClassNames(options)]);

    const [firstHalf, setFirstHalf] = useState([]);
    const [secondHalf, setSecondHalf] = useState([]);

    useEffect(() => {
        if (blocks.length > 0) {
            const arr = [...blocks];
            const middleIndex = Math.ceil(arr.length / 2);
            setFirstHalf(arr.splice(0, middleIndex));
            setSecondHalf(arr.splice(-middleIndex));
        }
    }, [blocks]);

    return (
        <div className="flex container justify-center w-full">
            <div className="py-6 md:pt-10 app-m-w">
                <h2 className="mb-4 leading-snug md:leading-normal md:text-3xl">{title}</h2>
                <div className="hidden md:flex md:flex-col relative space-y-2">
                    <StyledSliderDiv className="relative flex justify-center overflow-hidden">
                        <div className="embla flex absolute top-0 bottom-0 w-[500px] z-10" ref={emblaRef}>
                            <BlockList array={firstHalf} />
                        </div>
                    </StyledSliderDiv>
                    <StyledSliderDiv className="relative  flex justify-center overflow-hidden">
                        <div className="embla__second flex absolute top-0 bottom-0 w-[1000px] z-10" ref={emblaRef2}>
                            <BlockList array={secondHalf} second />
                        </div>
                    </StyledSliderDiv>
                </div>
                <div className="flex flex-col md:hidden">
                    <MobileBlockList blocks={blocks} />
                </div>
            </div>
        </div>
    );
};

USPBlock.propTypes = {
    title: PropTypes.string.isRequired,
    blocks: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string,
        })
    ).isRequired,
};

export default USPBlock;
