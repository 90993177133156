import styled from 'styled-components';

const StyledSliderDiv = styled.div`
    overflow: hidden;
    padding: 2rem 1rem;
    cursor: grab;

    &:active {
        cursor: grabbing;
    }
    .embla__container {
        display: flex;
    }
    .embla__slide {
        position: relative;
        flex: 0 0 75%;
        margin-right: 20px;
        min-height: 275px;
    }
    @media (min-width: 640px) {
        .embla {
            padding: 0 2rem;
        }
        .embla__slide {
            flex: 0 0 27%;
            min-height: 225px;
        }
    }
    @media (min-width: 1024px) {
        .embla {
            padding: 0 0rem;
        }
        .embla__slide {
            flex: 0 0 25%;
            min-height: 325px;
        }
    }
`;

export default StyledSliderDiv;
