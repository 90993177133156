import styled from 'styled-components';
import { variables } from '@/assets/styled';

export const StyledContent = styled.div`
    a {
        position: relative;
        display: inline-block;
        z-index: 1;
        color: ${variables.colors.blue.dark};
        font-weight: 700;
        text-decoration: underline;

        &:hover {
            color: ${variables.colors.pink.soft};
        }

        ::before {
            position: absolute;
            width: calc(100% + 3px);
            height: 8px;
            content: '';
            left: -3px;
            bottom: 2px;
            background-color: #fff;
            border-radius: 1px;
            z-index: -1;
        }
    }

    ul,
    ol {
        padding-bottom: 2rem;
        padding-top: 0.5rem;
        color: ${variables.colors.text.default};
        font-size: 1.125rem;
        ul,
        ol {
            padding-left: 1.5rem;
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    li::before {
        content: '•';
        font-weight: bold;
        display: inline-block;
        width: 1em;
        color: ${variables.colors.blue.dark};
    }

    p {
        padding: 0 0 0.5rem;
    }

    h2 {
        margin-top: 1.5rem;
        margin-bottom: 0.5rem;
    }

    h3 {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    overflow-wrap: break-word;
    word-wrap: break-word;

    .raw-html-embed {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 56.25%; /* 16:9 */

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }
    }
`;
