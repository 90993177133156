import React from 'react';
import unified from 'unified';
import rehypeParse from 'rehype-parse';
import rehypeReact from 'rehype-react';
import Image from 'next/image';
import { shimmer, toBase64 } from '@/components/General/Shimmer';

export const processor = unified()
    .use(rehypeParse, { fragment: true })
    .use(rehypeReact, {
        createElement: React.createElement,
        components: {
            // eslint-disable-next-line react/display-name
            img: (imgProps) => {
                if (imgProps.width && imgProps.height) {
                    return (
                        <Image
                            layout="intrinsic"
                            {...imgProps}
                            placeholder="blur"
                            blurDataURL={`data:image/svg+xml;base64,${toBase64(shimmer(700, 475))}`}
                        />
                    );
                }
                if (imgProps.style && imgProps.style.width && imgProps.style.height) {
                    return (
                        <Image
                            layout="intrinsic"
                            width={imgProps.style.width}
                            height={imgProps.style.height}
                            placeholder="blur"
                            blurDataURL={`data:image/svg+xml;base64,${toBase64(shimmer(700, 475))}`}
                            {...imgProps}
                        />
                    );
                }
                return <img alt="" {...imgProps} />;
            },
            // eslint-disable-next-line react/display-name
            a: (aProps) => (
                <a
                    {...aProps}
                    rel="noopener noreferrer"
                    className="overflow-hidden flex-1"
                    style={{ display: 'inline' }}
                >
                    {aProps.children}
                </a>
            ),
            // eslint-disable-next-line react/display-name
            p: (pProps) => {
                const elements = pProps.children
                    .map((child) => child.type?.name || child.type)
                    .filter((child) => child !== undefined);

                // This is needed in case we got an emoji + link. The emoji is a span, the link is an a.
                if (elements.length === 2 && elements.includes('span') && elements.includes('a')) {
                    return <p {...pProps} className="flex flex-nowrap gap-2" />;
                }

                return <p {...pProps} />;
            },
            // eslint-disable-next-line react/display-name, jsx-a11y/anchor-has-content, jsx-a11y/control-has-associated-label
            oembed: (oembedProps) => <a href={oembedProps.url} className="embedly-card" />,
        },
    });
