import PropTypes from 'prop-types';
import { StyledMasonryBlogWrapper } from '@/pages/blog/styles';
import BlogPostPreview from '@/components/BlogPostPreview';

const renderColumns = (blogs = []) => {
    const columns = [];

    blogs.forEach((blog) => {
        columns.push(
            <BlogPostPreview
                view
                href={`/blog/${blog?.blogs?.data?.attributes.slug || ''}`}
                key={blog?.blogs?.data?.attributes.slug}
                title={blog?.blogs?.data?.attributes.title}
                image={blog?.blogs?.data?.attributes.thumbnail}
            />
        );
    });

    return columns;
};

const BlogBlock = ({ blogs, title }) => {
    if (!blogs) return;

    return (
        <div className="mt-12 container">
            {title && <h2>{title}</h2>}
            <StyledMasonryBlogWrapper className="flex flex-col space-y-6 md:grid md:grid-cols-2 md:space-y-0 md:gap-4 xl:grid-cols-3 mb-20 mt-4">
                {renderColumns(blogs)}
            </StyledMasonryBlogWrapper>
        </div>
    );
};

BlogBlock.propTypes = {
    blogs: PropTypes.object,
    title: PropTypes.string,
};

export default BlogBlock;
