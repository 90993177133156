import React from 'react';
import Hero from '@/components/Hero';

const RenderHeroComponent = (components) =>
    components.map((component, index) =>
        component?.__component === 'home.hero' ? (
            <Hero
                key={index}
                title={component.title}
                slogan={component.slogan}
                description={component.description}
                buttons={component.buttons}
            />
        ) : null
    );

export default RenderHeroComponent;
