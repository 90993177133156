import React from 'react';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';

const GetCanonical = (homePage) => {
    const router = useRouter();
    const defaultCanonical =
        process?.env?.NEXT_PUBLIC_SITE_URL && router?.pathname
            ? `${process.env.NEXT_PUBLIC_SITE_URL}${router.pathname}`
            : '';
    if (homePage.configurations && homePage.configurations.length > 0) {
        return homePage?.configurations.map((configuration, index) => {
            switch (configuration.__component) {
                case 'frontend.seo-configuratie':
                    return (
                        <NextSeo
                            key={index}
                            canonical={configuration.canonical ? configuration.canonical : defaultCanonical}
                        />
                    );
                default:
                    return '';
            }
        });
    }
};

export default GetCanonical;
