import styled from 'styled-components';
import { variables } from '@/assets/styled';

export const StyledImage = styled.img`
    & {
        height: 258px;

        @media (min-width: ${variables.screens.md}) {
            height: 344px;
        }
    }
`;

export const StyledHTML = styled.div`
    a {
        position: relative;
        display: inline-block;
        z-index: 1;
        color: ${variables.colors.blue.dark};
        font-weight: 700;

        ::before {
            position: absolute;
            width: calc(100% + 3px);
            height: 8px;
            content: '';
            left: -3px;
            bottom: 2px;
            background-color: #fff;
            border-radius: 1px;
            z-index: -1;
        }
    }
`;
