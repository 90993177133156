import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { variables } from '@/assets/styled';

const WIDTH_MIN = 0;
const WIDTH_XS = 'xs';
const WIDTH_SM = 'sm';
const WIDTH_MD = 'md';
const WIDTH_LG = 'lg';
const WIDTH_XL = 'xl';
const WIDTH_MAX = 9000;

const pickWidth = function (setMaxWidth) {
    switch (setMaxWidth) {
        case WIDTH_XS:
            return variables.screenWidths.xs;
        case WIDTH_SM:
            return variables.screenWidths.sm;
        case WIDTH_MD:
            return variables.screenWidths.md;
        case WIDTH_LG:
            return variables.screenWidths.lg;
        case WIDTH_XL:
            return variables.screenWidths.xl;
        default:
            return WIDTH_MIN;
    }
};

const RenderByScreenWidth = ({ maxWidth = WIDTH_MAX, minWidth = WIDTH_MIN, children }) => {
    const [isWithinRange, setIsWithinRange] = useState(false);

    const chosenMaxWidth = typeof maxWidth === 'number' ? maxWidth : pickWidth(maxWidth);
    const chosenMinWidth = typeof minWidth === 'number' ? minWidth : pickWidth(minWidth);

    useEffect(() => {
        if (window?.innerWidth) {
            setIsWithinRange(window.innerWidth >= chosenMinWidth && window.innerWidth < chosenMaxWidth);

            window.addEventListener('resize', () => {
                setIsWithinRange(window.innerWidth >= chosenMinWidth && window.innerWidth < chosenMaxWidth);
            });
        }
    }, [chosenMinWidth, chosenMaxWidth]);

    return isWithinRange ? <>{children}</> : null;
};

RenderByScreenWidth.propTypes = {
    children: PropTypes.any,
    maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default RenderByScreenWidth;
