import React from 'react';

const RenderConfigurations = (homePage) =>
    homePage.configurations.map((configuration, index) => {
        switch (configuration.__component) {
            case 'frontend.seo-configuratie':
                return (
                    <React.Fragment key={index}>
                        <title>
                            {configuration.meta_title
                                ? configuration.meta_title
                                : homePage.title ?? "Blue Flamingo's | Blog post"}
                        </title>
                        <meta
                            name="description"
                            content={configuration.meta_description ? configuration.meta_description : ''}
                        />
                        {configuration.structured_data ? (
                            <script
                                type="application/ld+json"
                                dangerouslySetInnerHTML={{ __html: JSON.stringify(configuration.structured_data) }}
                            />
                        ) : null}
                    </React.Fragment>
                );
            default:
                return '';
        }
    });

export default RenderConfigurations;
