import styled from 'styled-components';
import { variables } from '@/assets/styled';

export const StyledCloseMagnetContainer = styled.div`
    top: 4rem;
    right: 1.5rem;

    @media (min-width: ${variables.screens.lg}) {
        right: -15px;
        top: 0;
    }
`;
