import React from 'react';
import PropTypes from 'prop-types';
import AboutYouCard from '@/components/AboutYouCard';

const AboutYouBlock = ({ blocks, title, description }) => (
    <div className="flex container">
        <div className="py-6 md:pt-10 w-full">
            <h2 className="mb-4 leading-snug md:leading-normal md:text-3xl">{title}</h2>
            <div className="font-body w-full md:w-1/2 " dangerouslySetInnerHTML={{ __html: description }} />
            <div className="md:flex mt-4 flex-wrap gap-x-6 w-full">
                {blocks?.map((block, i) => (
                    <div key={i} className="md:w-[calc(33.333%_-_1rem)] py-3 flex items-stretch">
                        <AboutYouCard block={block} />
                    </div>
                ))}
            </div>
        </div>
    </div>
);

AboutYouBlock.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    blocks: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            description: PropTypes.string,
            icon: PropTypes.shape({
                url: PropTypes.string,
            }),
            pagina: PropTypes.shape({
                slug: PropTypes.string,
            }),
        })
    ),
};

export default AboutYouBlock;
