import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import styled from 'styled-components';
import Link from 'next/link';
import Icon from '@/components/Icon';
import { shimmer, toBase64 } from '../General/Shimmer';

const StyledBlogPost = styled.a`
    min-height: 326px;
    max-height: 326px;
    display: flex;
    flex-direction: column;

    .icon {
        margin-left: 1rem;
        min-width: 25px;
        min-height: 25px;
    }
`;

const BlogPostPreview = React.forwardRef(
    ({ title = '', image = '', href = '', className = '', loading = false } = {}, ref) => (
        <Link href={href}>
            <StyledBlogPost
                ref={ref}
                href={href}
                className={`${className} ${
                    image?.data?.attributes.url ? '' : 'justify-center items-center'
                } group h-2/3 rounded-md bg-grey-white bg-gradient-to-br hover:from-orange hover:via-pink-soft hover:to-pink-soft shadow-blog overflow-hidden cursor-pointer`}
            >
                <div className="image relative h-56 w-full">
                    {image?.data?.attributes.url ? (
                        <Image
                            className="w-full max-w-full h-48 object-cover group-hover:opacity-50"
                            src={`${process.env.NEXT_PUBLIC_STRAPI_URL}${image?.data?.attributes.url}` || ''}
                            alt={`Thumbnail blog post '${title}'`}
                            layout="fill"
                            objectFit="cover"
                            placeholder="blur"
                            blurDataURL={`data:image/svg+xml;base64,${toBase64(shimmer(700, 475))}`}
                        />
                    ) : (
                        <Image
                            className="w-full max-w-full h-48 object-cover group-hover:opacity-50"
                            src="/images/placeholder.png"
                            alt="Lorem ipsum dolor sit amet, consectetur adipiscing."
                            layout="fill"
                            objectFit="cover"
                            placeholder="blur"
                            blurDataURL={`data:image/svg+xml;base64,${toBase64(shimmer(700, 475))}`}
                        />
                    )}
                </div>

                <div className="w-full p-6 flex justify-between items-center">
                    {loading && (
                        <div className="flex-1 space-y-6 py-1">
                            <div className="space-y-3">
                                <div className="grid grid-cols-3 gap-4">
                                    <div className="h-4 bg-grey-ghostWhite rounded col-span-2" />
                                    <div className="h-4 bg-grey-ghostWhite rounded col-span-1" />
                                </div>
                                <div className="h-4 bg-grey-ghostWhite rounded" />
                            </div>
                        </div>
                    )}
                    <h2 className="text-text text-lg font-bold group-hover:text-grey-white">{title}</h2>
                    <Icon name="arrow" fill="text" className="icon" />
                </div>
            </StyledBlogPost>
        </Link>
    )
);

BlogPostPreview.displayName = 'BlogPostPreview';

BlogPostPreview.propTypes = {
    title: PropTypes.string,
    image: PropTypes.objectOf(PropTypes.string),
    href: PropTypes.string,
    className: PropTypes.string,
    loading: PropTypes.bool,
};

export default BlogPostPreview;
