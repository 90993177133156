import PropTypes from 'prop-types';
import CountUp from 'react-countup';
import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { StyledNumber } from './styles';

const Number = ({ digit, text }) => {
    const [viewPortEntered, setViewPortEntered] = useState(false);
    return (
        <StyledNumber className="w-1/2 py-4 flex flex-col md:w-1/4">
            <CountUp start={viewPortEntered ? null : 0} end={digit} duration="2">
                {({ countUpRef }) => (
                    <VisibilitySensor
                        active={!viewPortEntered}
                        onChange={(isVisible) => {
                            if (isVisible) {
                                setViewPortEntered(true);
                            }
                        }}
                        delayedCall
                    >
                        <p
                            ref={countUpRef}
                            className="flex self-center text-4xxl md:text-7xl text-gradient font-display font-black leading-none md:self-start"
                        />
                    </VisibilitySensor>
                )}
            </CountUp>
            <p className="flex self-center text-sm xs:text-lg md:text-2xl font-body font-light text-text-variant text-opacity-50 md:self-start">
                {text}
            </p>
        </StyledNumber>
    );
};

Number.propTypes = {
    digit: PropTypes.number,
    text: PropTypes.string,
};

export default Number;
