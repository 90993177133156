import PropTypes from 'prop-types';
import React from 'react';
import Image from 'next/image';
import RenderByScreenWidth from '@/components/RenderByScreenWidth';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import { StyledHeroWrapper } from '@/pages/index/styles';

const Hero = ({ slogan, title, description, buttons }) => (
    <StyledHeroWrapper>
        <RenderByScreenWidth maxWidth="lg">
            <div className="absolute w-[1600px] h-[1600px] -top-[32rem] -right-[20rem]">
                <Image
                    src="/images/hero2x-desktop.webp"
                    layout="fill"
                    objectFit="contain"
                    className="z--1 opacity-40"
                    quality={90}
                    alt={title}
                    priority
                    sizes="(min-width: 450px) 828px, 640px" // 640px used if width < 450px (xs width);
                />
            </div>
        </RenderByScreenWidth>
        <section id="hero-content" className="pt-16 flex justify-center w-full h-full hero-wrapper">
            <div className="pt-24 container lg:pt-32">
                {slogan && (
                    <h1 className="hero-title bg-clip-text bg-gradient-to-r from-orange  to-pink-soft leading-snug text-xl md:text-2xl">
                        {slogan}
                    </h1>
                )}

                {title && (
                    <h1 className="hero-title text-blue bg-clip-text bg-gradient-to-r from-blue-dark to-blue-light leading-snug sm:w-2/3 text-4xl md:text-5xl">
                        {title}
                    </h1>
                )}
                {description && (
                    <div className="font-body md:w-1/2 w-full mt-6" dangerouslySetInnerHTML={{ __html: description }} />
                )}
                {buttons?.length > 0 && (
                    <div className="mt-12 flex flex-col md:block mb-12 md:mb-0">
                        {buttons.map((button, index) => (
                            <Button
                                className="mt-3 mr-4 relative"
                                as="a"
                                href={button.url}
                                key={index}
                                backgroundColor={button.type === 'primary' ? 'pink' : 'pink-secondary'}
                            >
                                {button.text}
                                <Icon
                                    name="arrow"
                                    fill="white"
                                    size={20}
                                    className="inline-block ml-4 absolute md:relative md:right-0 right-8"
                                />
                            </Button>
                        ))}
                    </div>
                )}
            </div>
        </section>
    </StyledHeroWrapper>
);

Hero.propTypes = {
    title: PropTypes.string,
    slogan: PropTypes.string,
    description: PropTypes.string,
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string,
            url: PropTypes.string,
            type: PropTypes.string,
        })
    ),
};

export default Hero;
