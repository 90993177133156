import React from 'react';
import BusinessFigures from '@/components/BusinessFigures';
import Introduce from '@/components/Introduce';
import USPBlock from '@/components/USPBlock';
import PartnerLogoBlock from '@/components/PartnerLogoBlock';
import MagnetBlock from '@/components/MagnetBlock';
import { StyledContent } from '@/pages/blog/[...slug]/styles';
import CallToActionBlock from '@/components/CallToActionBlock';
import { processor } from '@/helpers/general';
import AboutYouBlock from '@/components/AboutYouBlock';
import SliderBlock from '@/components/SliderBlock';
import BlogBlock from '@/components/BlogBlock';

const RenderFirstComponents = (components, bottomEnabled = true, showFlamingo = false) =>
    components.map((component, index) => {
        const floatingComponents = ['frontend.magnet-blok'];

        const lastComponent = components
            .filter((value) => floatingComponents.includes(value.__component) === false)
            .map((value) => value.__component)
            .slice(-1)[0];

        switch (component?.__component) {
            case 'home.bedrijfscijfers-blok':
                return <BusinessFigures key={index} digits={component.digits} />;
            case 'frontend.bouwstenen-blok':
                return <USPBlock key={index} title={component.title} blocks={component.blocks} />;
            case 'frontend.samenwerkingen-blok':
                return (
                    <PartnerLogoBlock
                        key={index}
                        title={component?.title}
                        description={component?.description}
                        text_align={component?.text_align}
                        logos={component?.logos}
                    />
                );
            case 'frontend.magnet-blok':
                return <MagnetBlock key={`${component?.__component}-${index}`} configuration={component} />;
            case 'frontend.over-jou-blok':
                return (
                    <AboutYouBlock
                        key={index}
                        title={component.title}
                        description={component.description}
                        blocks={component.blocks}
                    />
                );
            case 'frontend.even-voorstellen-blok':
                return (
                    <Introduce
                        key={index}
                        title={component.title}
                        description={component.description}
                        image={component.image}
                        buttonText={component.button_text}
                        buttonUrl={component.button_url}
                    />
                );
            case 'general.content':
                return (
                    <div
                        key={`${component?.__component}-${index}`}
                        className={`container ${index === 0 ? 'pb-6 md:pb-12' : 'py-6 md:pb-12'}`}
                    >
                        <StyledContent key={component?.__component} className="component-markup md:w-8/12">
                            {processor.processSync(component?.content).result}
                        </StyledContent>
                    </div>
                );
            case 'frontend.cta-blok':
                return (
                    <CallToActionBlock
                        key={`${component?.__component}-${index}`}
                        configuration={component}
                        bottom={bottomEnabled && component?.__component === lastComponent}
                        flamingo={showFlamingo}
                    />
                );
            case 'frontend.slider':
                return <SliderBlock key={component?.__component} images={component?.images} />;
            case 'frontend.blog-blok':
                return <BlogBlock key={component?.__component} title={component?.title} blogs={component?.blogs} />;
            default:
                return '';
        }
    });

export default RenderFirstComponents;
