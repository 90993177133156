import styled from 'styled-components';
import { variables } from '@/assets/styled';

export const StyledNumber = styled.div`
    .text-gradient {
        display: block;
        background-color: ${variables.colors.blue.dark};
        background-image: linear-gradient(
            to bottom right,
            ${variables.colors.blue.light},
            ${variables.colors.blue.dark},
            ${variables.colors.blue.dark}
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`;
