import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import { StyledAboutYouCard } from './styles';

const AboutYouCard = ({ block }) => (
    <StyledAboutYouCard
        className={`p-4 ${!block.description && 'pb-2 self-start'} w-full bg-grey-ghostWhite rounded-md relative`}
        as={block.pagina?.data?.attributes?.slug ? 'a' : 'div'}
        href={
            block.pagina?.data?.attributes?.slug
                ? `${process.env.NEXT_PUBLIC_SITE_URL}/${block.pagina.data.attributes.slug}`
                : ''
        }
    >
        <div className="grid grid-cols-[35px_1fr_25px]">
            {block.icon?.data?.attributes?.url && (
                <div>
                    <Image
                        src={`${process.env.NEXT_PUBLIC_STRAPI_URL}${block.icon?.data?.attributes?.url}`}
                        alt={block.title ? block.title : 'over jou Afbeelding'}
                        width={24}
                        height={24}
                    />
                </div>
            )}

            <h3
                className={`text-lg font-body leading-normal text-grey-blueDarker font-bold col-start-2 title ${
                    block.description && 'mb-2'
                }`}
            >
                {block.title}
            </h3>

            {block.description && <p className="font-body col-span-2 text-base leading-snug">{block.description}</p>}
        </div>
    </StyledAboutYouCard>
);

AboutYouCard.propTypes = {
    block: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string,
        icon: PropTypes.shape({
            data: PropTypes.shape({
                attributes: PropTypes.shape({
                    url: PropTypes.string,
                }),
            }),
        }),
        pagina: PropTypes.shape({
            data: PropTypes.shape({
                attributes: PropTypes.shape({
                    slug: PropTypes.string,
                }),
            }),
        }),
    }),
};
export default AboutYouCard;
