import Head from 'next/head';
import PropTypes from 'prop-types';
import React from 'react';
import Image from 'next/image';
import { getHomePage } from '@/lib/api';
import RenderByScreenWidth from '@/components/RenderByScreenWidth';
import RenderFirstComponents from './HomepageComponents/RenderComponents';
import RenderHeroComponent from './HomepageComponents/RenderHeroComponent';
import RenderConfigurations from './HomepageComponents/RenderConfigurations';
import GetCanonical from './HomepageComponents/GetCanonical';

const Home = ({ homePage }) => (
    <div>
        <RenderByScreenWidth minWidth="lg">
            <div className="absolute w-[1700px] h-[1700px] -top-[32rem] right-0">
                <Image
                    src="/images/hero2x-desktop.webp"
                    layout="fill"
                    objectFit="contain"
                    className="hero-image "
                    quality={95}
                    priority
                />
            </div>
        </RenderByScreenWidth>
        {GetCanonical(homePage)}
        <Head>{RenderConfigurations(homePage)}</Head>
        <main>
            {RenderHeroComponent(homePage.components)}
            <div className="bg-blob-bg bg-no-repeat bg-cover lg:bg-auto">
                {RenderFirstComponents(homePage.components)}
            </div>
        </main>
    </div>
);

export const getStaticProps = async () => {
    const homePage = (await getHomePage()) || {};
    return {
        props: { homePage },
        revalidate: 60,
    };
};

Home.propTypes = {
    homePage: PropTypes.object,
};

export default Home;
