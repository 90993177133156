import PropTypes from 'prop-types';
import React from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import Image from 'next/image';
import StyledSliderDiv from './styles';
import { shimmer, toBase64 } from '../General/Shimmer';

const SliderBlock = ({ images }) => {
    const [emblaRef] = useEmblaCarousel({ loop: true });

    return (
        <StyledSliderDiv ref={emblaRef}>
            <div className="embla__container">
                {images &&
                    images.data.map((image, index) => (
                        <div key={index} className="embla__slide relative">
                            <Image
                                src={`${process.env.NEXT_PUBLIC_STRAPI_URL}${image.attributes.url}`}
                                layout="fill"
                                alt={`Thumbnail ${image.attributes.url}`}
                                objectFit="cover"
                                className="rounded-md"
                                placeholder="blur"
                                blurDataURL={`data:image/svg+xml;base64,${toBase64(shimmer(700, 475))}`}
                            />
                        </div>
                    ))}
            </div>
        </StyledSliderDiv>
    );
};

SliderBlock.propTypes = {
    images: PropTypes.arrayOf(PropTypes.object),
};

export default SliderBlock;
