import Image from 'next/image';
import PropTypes from 'prop-types';
import React from 'react';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import { StyledHTML } from './styles';

const Introduce = ({ title, description, image, buttonText, buttonUrl }) => (
    <div className="flex justify-center container w-full">
        <div className="py-6 app-m-w flex flex-wrap items-center justify-center">
            <div className="order-last md:w-3/6 mt-12 ">
                <Image
                    src={`${process.env.NEXT_PUBLIC_STRAPI_URL}${image.data.attributes.url}`}
                    width="488"
                    alt={title}
                    height="344"
                    className="object-cover object-top w-full rounded-md md:order-2"
                />
            </div>
            <div className="md:w-3/6 md:pr-12">
                <h2 className="mt-6 mb-2 md:mt-2 md:order-1 md:text-3xl">{title}</h2>
                <StyledHTML className="font-body md:order-3" dangerouslySetInnerHTML={{ __html: description }} />
                <Button small href={buttonUrl} as="a" backgroundColor="pink-secondary" className="mt-6 md:mt-12">
                    {buttonText}
                    <Icon name="arrow" fill="white" size={20} className="inline-block ml-4" />
                </Button>
            </div>
        </div>
    </div>
);

Introduce.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    buttonText: PropTypes.string.isRequired,
    buttonUrl: PropTypes.string.isRequired,
};

export default Introduce;
