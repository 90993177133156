import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';

const PartnerLogoBlock = ({ title, description, text_align, logos }) => (
    <div className="flex container justify-center w-full">
        <section className="py-10 app-m-w">
            <h2 className={`leading-snug md:leading-normal md:text-3xl text-${text_align}`}>{title}</h2>
            <p className={`font-body w-full text-${text_align}`}>{description}</p>
            <div className="flex flex-wrap gap-y-2 justify-center mt-10 -mx-1">
                {logos?.length > 0 &&
                    logos.map((logo, index) => (
                        <a
                            href={logo?.url}
                            target="_blank"
                            rel="noreferrer"
                            className="basis-1/2 sm:basis-1/3 md:basis-1/4 lg:basis-1/5 px-1 group"
                            key={index}
                        >
                            <div className="bg-grey-white lg:bg-grey-ghostWhite py-3 px-2 xs:py-4 xs:px-4 sm:py-6 sm:px-8 rounded-md">
                                <div className="h-12 w-full relative">
                                    {logo?.image?.data?.attributes?.url && (
                                        <Image
                                            src={
                                                logo?.image?.data?.attributes?.url
                                                    ? `${process.env.NEXT_PUBLIC_STRAPI_URL}/${
                                                          logo?.image?.data?.attributes?.url.startsWith('/')
                                                              ? logo?.image?.data?.attributes?.url.substring(1)
                                                              : logo?.image?.data?.attributes?.url
                                                      }`
                                                    : ''
                                            }
                                            alt="Partner logo"
                                            className="opacity-75 hue-rotate-180 saturate-100 grayscale group-hover:filter-none group-hover:opacity-100"
                                            layout="fill"
                                            objectFit="contain"
                                        />
                                    )}
                                </div>
                            </div>
                        </a>
                    ))}
            </div>
        </section>
    </div>
);

PartnerLogoBlock.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    text_align: PropTypes.string,
    logos: PropTypes.arrayOf(PropTypes.object),
};

export default PartnerLogoBlock;
